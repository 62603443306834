<template>
  <div>
    <v-app id="auth">
      <vue-particles
        color="#00abab"
        :particleOpacity="1"
        :particlesNumber="50"
        shapeType="circle"
        :particleSize="4"
        linesColor="#00abab"
        :linesWidth="1"
        :lineLinked="true"
        :lineOpacity="0.4"
        :linesDistance="150"
        :moveSpeed="3"
        :hoverEffect="true"
        hoverMode="repulse"
        :clickEffect="false"
        clickMode="repulse"
      >
      </vue-particles>
      <transition name="fade">
        <router-view />
      </transition>
    </v-app>
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  name: "auth-layout",
  methods: {
    ...mapActions(["setThemeDark"]),
  },
  created() {
    this.setThemeDark(false);
  },
};
</script>
<style>
#auth {
  background-color: #eee !important;
}
#particles-js {
  position: fixed;
    top: 0;
    left: 0;
    height: 100% !important;
    width: 100% !important;
}
</style>